<template>
  <el-dialog v-model="props.value" lock-scroll :close-on-click-modal="false" class="hide-btn" title="" align-center
             width="530">
    <div class="about-container flex fd-c jc-fs ai-c">
      <img src="@/assets/img/toast-success.png" class="" alt="">
      <div class="fs-20 lh-34 fw-600 ta-c">{{ props.text }}</div>
    </div>
  </el-dialog>
</template>

<script setup>
import {defineProps, ref, watch} from 'vue'

const props = defineProps({
  value: {
    type: Boolean,
    default: false
  },
  text: {
    type: String,
    default: ''
  },
  duration: {
    type: String,
    default: '2000'
  }
})

const emit = defineEmits(['change', ''])

watch(props, (newv) => {
  if (newv.value) {
    setTimeout(() => {
      emit('change', false)
    }, props.duration)
  }
}, {deep: true, immediate: true})

</script>

<style scoped lang="less">
.about-container {
  width: 530px;
  height: 275px;
  background: #000000;
  opacity: 0.7;
  border-radius: 40px;
  padding-top: 52px;

  img {
    width: 100px;
    height: 100px;
    margin: 0 auto 37px;
  }
}
</style>