<template>
  <div class="content">
    <div class="top-container">
      <div class="top-header flex jc-sb ai-c px-2">
        <img src="@/assets/img/top-logo.png" class="top-logo" alt="">
        <div class="flex jc-fe ai-c">
          <span class="fs-16 fw-600 lh-24 mr-4 cp" @click="">Home</span>
          <span class="fs-16 fw-600 lh-24 mr-4 cp" @click="aboutDialog = true">About</span>
          <button class="demo-btn fs-14 fw-600 cp" @click="todemo">Demo</button>
          <div class="white-bg cp">
            <button class="contact-btn fs-14 fw-700 cp" @click="contactDialog = true">CONTACT US</button>
          </div>
        </div>
      </div>
      <div class="top-content flex">
        <div class="top-text">
          <div class="">Global NFT</div>
          <div class="">SaaS Platform</div>
          <div>A one-stop NFT solution service integrating NFT minting, sales, settlement, and management.</div>
          <div class="top-btn-container flex">
            <div class="white-bg cp mr-2">
              <button class="contact-btn fs-20 fw-700 cp" @click="contactDialog = true">CONTACT US</button>
            </div>
            <div class="demo-bg cp">
              <button class="demo-btn fs-20 fw-600 cp" @click="todemo">Demo</button>
            </div>

          </div>
        </div>
        <img src="@/assets/img/home-header-img.png" class="top-img" alt="">
      </div>
    </div>
    <div class="advantage-container">
      <div class="title">Our Advantages</div>
      <div class="advantage-content flex jc-c ai-c">
        <div class="advantage-item flex jc-c ai-c fd-c">
          <img src="@/assets/img/home-advantage3.png" class="img-item" alt="">
          <div class="advantage-text">Global fiat purchase</div>
        </div>
        <div class="advantage-item flex jc-c ai-c fd-c">
          <img src="@/assets/img/home-advantage2.png" class="img-item" alt="">
          <div class="advantage-text">Cryptocurrency purchase</div>
        </div>
        <div class="advantage-item flex jc-c ai-c fd-c">
          <img src="@/assets/img/home-advantage1.png" class="img-item" alt="">
          <div class="advantage-text">Brand customization</div>
        </div>
        <div class="advantage-item flex jc-c ai-c fd-c">
          <img src="@/assets/img/home-advantage4.png" class="img-item" alt="">
          <div class="advantage-text">High security and efficiency</div>
        </div>
      </div>
    </div>
    <div class="demo-container flex jc-c ai-c">
      <img src="@/assets/img/home-demo.png" class="demo-img" alt="">
      <div>
        <div class="fs-42 fw-700 lh-64 mb-3">Convenient and fast</div>
        <div class="fs-24 lh-40 fw-500">Web and APP cross-platform</div>
        <div class="fs-24 lh-40 fw-500">deployment, easy application in all</div>
        <div class="fs-24 lh-40 fw-500">scenarios, and more convenient to
        </div>
        <div class="fs-24 lh-40 fw-500">buy NFT.</div>
      </div>
    </div>
    <div class="demo-container flex jc-c ai-c mt-158">
      <div>
        <div class="fs-42 fw-700 lh-64 mb-3">Global payment coverage</div>
        <div class="fs-24 lh-40 fw-500 ta-r">Support purchasing legal cryptocurrencies from</div>
        <div class="fs-24 lh-40 fw-500 ta-r">160 countries/regions using Visa, Mastercard,</div>
        <div class="fs-24 lh-40 fw-500 ta-r">mobile wallets, and bank transfers. The</div>
        <div class="fs-24 lh-40 fw-500 ta-r">payment success rate has increased by 98%.</div>
      </div>
      <img src="@/assets/img/home-pay.png" class="pay-img" alt="">
    </div>

    <div class="work-container">
      <div class="work-content">
        <div class="fs-44 lh-64 fw-700 ta-c">Brand Value</div>
        <div class="fs-24 lh-40 fw-500 ta-c">As a form of digital art, NFT is very suitable for promoting the company's
          IP image and quickly
        </div>
        <div class="fs-24 lh-40 fw-500 ta-c">enhancing brand value and awareness. NFT also provides a new marketing
          method and
        </div>
        <div class="fs-24 lh-40 fw-500 ta-c">brand image shaping opportunity for brands as digital collectibles.</div>
      </div>
      <img src="@/assets/img/home-nft.png" class="nft-img" alt="">
    </div>

    <div class="bg-f5">
      <div class="platform-container adaptation fd-c flex jc-c ai-c ">
        <div class="fs-44 lh-60 fw-700 ta-c">Easily manage your NFT platform</div>
        <div class="fs-24 lh-40 fw-500 ta-c">NFT minting, NFT on-chain, NFT management, fund management, user
          management,
        </div>
        <div class="fs-24 lh-40 fw-500 ta-c">order management, etc. are all fully equipped and powerful backend systems.
        </div>
        <img src="@/assets/img/home-platform.png" class="platform-img" alt="">
      </div>
    </div>

    <div class="support-container adaptation">
      <div class="fs-44 lh-60 fw-700 ta-c">Global leading technology and services</div>
      <div class="fs-18 lh-34 fw-500 ta-c">We provide the best guarantee for your fund security.</div>
      <div class="support-item">
        <div class="fs-24 lh-36 fw-700">Blockchain development technology</div>
        <div class="fs-18 lh-40 fw-500">• Smart contracts, collectible minting</div>
        <div class="fs-18 lh-40 fw-500">• Collectible issuance, collectible on-chain</div>
        <img src="@/assets/img/home-support4.png" class="support-img" alt="">
      </div>
      <div class="support-item">
        <div class="fs-24 lh-36 fw-700">High concurrency security protection</div>
        <div class="fs-18 lh-40 fw-500">• Strong system transaction concurrency capability</div>
        <div class="fs-18 lh-40 fw-500">• Strong load balancing processing capability</div>
        <img src="@/assets/img/home-support2.png" class="support-img" alt="">
      </div>
      <div class="support-item">
        <div class="fs-24 lh-36 fw-700">Customized services</div>
        <div class="fs-18 lh-40 fw-500">• Customize functions according to business needs</div>
        <div class="fs-18 lh-40 fw-500">• Free upgrades for later functions</div>
        <img src="@/assets/img/home-support3.png" class="support-img" alt="">
      </div>
      <div class="support-item">
        <div class="fs-24 lh-36 fw-700">24/7 service</div>
        <div class="fs-18 lh-40 fw-500">• Provide 24/7 technical support</div>
        <div class="fs-18 lh-40 fw-500"> • Exclusive customer service to provide help for you</div>
        <img src="@/assets/img/home-support1.png" class="support-img" alt="">
      </div>
    </div>

    <div class="bg-f5">
      <div class="partners-container adaptation">
        <div class="fs-44 lh-60 fw-700 ta-c">Partners</div>
        <div class="partners-box flex jc-fs fw-w ai-c">
          <div class="partner-item" v-for="item in partnerList" :key="item">
            <img :src="item" class="partner-img" alt="">
          </div>
        </div>
      </div>
    </div>

    <div class="contact-container adaptation">
      <div class="fw-700 fs-44 lh-64 ta-c">Contact us</div>
      <div class="fw-600 fs-20 lh-34 ta-c">Contact us for more information and the best solution for your project. After
        submitting your
      </div>
      <div class="fw-600 fs-20 lh-34 ta-c">information, we will contact you within 72 hours. Thank you!</div>
      <div class="contact-us-btn cp" style="color: #ffffff" @click="contactDialog = true">Contact us</div>
    </div>

    <div class="bg-f5" style="height: 120px;"></div>

    <div class="copy-right flex jc-c ai-c fs-18 fw-500 lh-28">
      @ Qameb LTD
    </div>


    <el-dialog v-model="contactDialog" lock-scroll :close-on-click-modal="false" title="" align-center width="720">
      <div class="dialog-content" v-loading="loading">
        <div class=" ta-c fs-32 lh-48 fw-700 mt-4">
          Contact Us
        </div>
        <div class="item-text">Country</div>
        <div class="item-value">
          <el-select v-model="country" class="" placeholder="Please select your country" size="large">
            <el-option
                v-for="item in countryList"
                :key="item.value"
                :label="item.text"
                :value="item.value"
            />
          </el-select>
        </div>
        <div class="item-text">Company name</div>
        <div class="item-value">
          <el-input v-model="company" placeholder="Please enter your company name "/>
        </div>
        <div class="item-text">Contact email</div>
        <div class="item-value">
          <el-input v-model="email" placeholder="Please enter your email address"/>
        </div>
        <div class="item-text">Business model introduction</div>
        <div class="item-value">
          <el-input class="item-area"
                    v-model="introduction"
                    :rows="4"
                    type="textarea"
                    placeholder="Please describe your business model introduction"
          />
        </div>
        <div class="contact-us-btn cp mt-4" @click="submit">OK</div>
        <img src="@/assets/img/home-close.png" @click="contactDialog = false" class="close-btn cp" alt="">
      </div>
    </el-dialog>


    <el-dialog v-model="aboutDialog" lock-scroll :close-on-click-modal="false" class="hide-btn" title="" align-center
               width="720">
      <div class="about-container">
        <img src="@/assets/img/home-about.png" alt="">
        <div class="fs-20 lh-34 fw-500 ta-c">PoPoNFT is the world's best NFT SaaS platform, supporting</div>
        <div class="fs-20 lh-34 fw-500 ta-c">global fiat and cryptocurrency payments, one-stop</div>
        <div class="fs-20 lh-34 fw-500 ta-c">technology services for NFT minting, sales, management,</div>
        <div class="fs-20 lh-34 fw-500 ta-c">settlement, etc. We have the best blockchain technology</div>
        <div class="fs-20 lh-34 fw-500 ta-c">and security architecture technology.</div>

        <img src="@/assets/img/home-close.png" @click="aboutDialog = false" class="close-btn cp" alt="">
      </div>
    </el-dialog>

    <!--    <el-backtop :bottom="100">-->
    <!--     -->
    <!--    </el-backtop>-->

    <toast @change="successToast = false" text="Submission successful. Please wait patiently." :value="successToast"/>
  </div>
</template>

<script setup>
import {reactive, ref, watchEffect} from 'vue'
import {ElMessage} from 'element-plus'
import Toast from '../components/Toast'

document.title = 'PoPoNFT'
let countryList = [
  {
    text: 'United States',
    value: 'usa'
  },
  {
    text: 'United Kingdom',
    value: 'uk'
  },
  {
    text: 'India',
    value: 'India'
  },
  {
    text: 'Japan',
    value: 'Japan'
  },
  {
    text: 'South Korea',
    value: 'Korea'
  },

  {
    text: 'Malaysia',
    value: 'Malaysia'
  },
  {
    text: 'Russia',
    value: 'Russia'
  }
]


let partnerList = reactive([])
let loading = ref(false)
let contactDialog = ref(false)
let successToast = ref(false)
let aboutDialog = ref(false)
let country = ref('United States')
let company = ref('')
let email = ref('')
let introduction = ref('')


watchEffect(async () => {
  for (let i = 1; i < 17; i++) {
    const module = await import(`../assets/img/logo-${i}.png`)
    partnerList.push(module.default)
  }
})

const submit = () => {
  if (!company.value) {
    ElMessage.error('Please enter your company name')
    return
  }
  if (!email.value) {
    ElMessage.error('Please enter your email address')
    return
  }
  let emailEx = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/
  if (!emailEx.test(email.value)) {
    ElMessage.error('Please enter the correct email address')
    return
  }
  loading.value = true

  let range = Math.floor(Math.random() * 3000) + 1000

  setTimeout(() => {
    contactDialog.value = false
    loading.value = false
    successToast.value = true
    country.value = ''
    company.value = ''
    email.value = ''
    introduction.value = ''
  }, range)

}

const todemo = ()=>{
  // location.href='https://jp-demo.poponft.com/'
  window.open('https://jp-demo.poponft.com/','_blank')
}

</script>


<style scoped lang="less">
@media screen and (max-width: 1280px) {
  .top-content {
    overflow: hidden;
  }
}

.adaptation {
  width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.content {
  width: 100%;
  min-width: 1200px;
  background: #F7F9FC;
}

.top-container {
  width: 100%;
  height: 920px;
  background-image: url("@/assets/img/home-top-bg.png");
  background-size: 100% 100%;

  .top-logo {
    width: 147px;
    height: 35px;
  }

  .top-content {
    width: 1200px;
    padding: 0 20px;
    color: #ffffff;
    margin: 90px auto 0;
    position: relative;
    //overflow: hidden;
  }

  .top-text {
    width: 639px;

    > div:first-child, > div:nth-child(2) {
      font-size: 80px;
      line-height: 96px;
      font-weight: 700;
    }

    > div:nth-child(3) {
      font-size: 20px;
      line-height: 34px;
      font-weight: 600;
      margin-top: 15px;
      margin-bottom: 70px;
    }
  }

  .top-img {
    position: absolute;
    top: 0;
    left: 645px;
    width: 620px;
    height: 504px;
  }

  .top-btn-container {
    .demo-bg {
      width: 134px;
      height: 60px;
      border-radius: 56px;
      background: transparent;
      color: #FFFFFF;
      overflow: hidden;
      border: 2px solid #ffffff;
      //border-radius: 16px;
      //background-clip: padding-box, border-box;
      //background-origin: padding-box, border-box;
      //background-image: linear-gradient(to right, #222, #222), linear-gradient(90deg, #8F41E9, #578AEF);
    }

    .demo-btn {
      width: 100%;
      height: 100%;
      border: none;
    }

    .white-bg, .contact-btn {
      width: 188px;
      height: 60px;
      font-weight: 700;
      background: #fff;
      border-radius: 56px;
      border: none;
    }

    .contact-btn {
      background: linear-gradient(90deg, #FFB545 0%, #2FB4FF 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }

    .demo-btn {
      background: linear-gradient(90deg, #FFCD82 0%, #75CDFF 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }
}

.top-header {
  width: 100%;
  height: 83px;
  color: #FFFFFF;

  .demo-btn {
    width: 94px;
    height: 40px;
    border: 2px solid #FFFFFF;
    font-weight: 600;
    border-radius: 56px;
    background: none;
    margin-right: 15px;
    color: #FFFFFF;
  }

  .white-bg, .contact-btn {
    width: 144px;
    height: 40px;
    font-weight: 700;
    background: #fff;
    border-radius: 56px;
    border: none;
  }

  .contact-btn {

    background: linear-gradient(90deg, #FFB545 0%, #2FB4FF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;


  }
}

.advantage-container {
  width: 1160px;
  height: 493px;

  border-radius: 60px;
  background: #ffffff;
  margin: 0 auto;
  transform: translateY(-136px);
  padding-top: 45px;
  backdrop-filter: blur(8px);

  .title {
    font-size: 40px;
    line-height: 60px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 40px;
  }

  .advantage-item {
    width: 200px;
    margin: 0 33px;
    color: #000000;

    .advantage-text {
      width: 150px;
      font-size: 20px;
      line-height: 30px;
      font-weight: 600;
      text-align: center;
    }

    img {
      width: 200px;
      height: 200px;
      margin-bottom: 10px;
    }
  }
}

.demo-container {
  width: 1160px;
  padding: 0 20px;
  height: 493px;
  margin: 38px auto 0;

  &.mt-158 {
    margin-top: 158px;
    margin-bottom: 179px;
  }

  .demo-img {
    width: 599px;
    height: 491px;
    margin-right: 70px;
  }

  .pay-img {
    width: 489px;
    height: 443px;
    margin-left: 70px;
  }
}

.work-container {
  width: 100%;
  height: 902px;
  background-image: url("@/assets/img/home-work.png");
  background-repeat: no-repeat;
  background-size: 100% 902px;

  .work-content {
    width: 1120px;
    padding: 111px 15px 0;
    margin: 0 auto;
    color: #fff;

    > div:first-child {
      margin-bottom: 45px;
    }

  }

  .nft-img {
    margin-top: 110px;
    height: 390px;
    width: 100%;
    object-fit: cover;
  }
}

.bg-f5 {
  background: #F5F5F5;;
}

.platform-container {
  padding-top: 110px;
  padding-bottom: 200px;

  > div:first-child {
    margin-bottom: 37px;
  }

  .platform-img {
    margin-top: 140px;
    width: 1009px;
    height: 642px;
  }

}

.support-container {
  padding-top: 160px;

  > div:first-child {
    margin-bottom: 17px;
  }

  > div:nth-child(2) {
    margin-bottom: 205px;
  }

  .support-item {
    display: inline-block;
    width: 50%;
    margin-bottom: 179px;
    position: relative;
    padding-left: 100px;

    .support-img {
      width: 154px;
      height: 154px;
      position: absolute;
      left: 20px;
      top: -63px;
    }

    > div:first-child {
      margin-bottom: 27px;
    }
  }
}

.partners-container {
  height: 1043px;
  padding-top: 200px;

  > div:first-child {
    margin-bottom: 100px;
  }

  .partner-item {
    width: 265px;
    height: 96px;
    margin-right: 32px;
    margin-bottom: 32px;
  }

  .partner-img {
    width: 265px;
    height: 96px;
  }

  .partner-item:nth-child(4n) {
    margin-right: 0;
  }
}

.contact-container {
  width: 100%;
  height: 472px;
  background-size: 100% 472px;
  color: #ffffff;
  padding-top: 90px;
  background-image: url("@/assets/img/home-contact.png");

  > div:first-child {
    margin-bottom: 22px;
  }

  .contact-us-btn {
    width: 236px;
    height: 74px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #000000;
    border-radius: 50px;
    color: #75CDFF;
    margin: 60px auto 0;
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
  }
}

.copy-right {
  height: 110px;
  color: #777;
}

/deep/ .el-dialog__body {
  padding: 0;
}

/deep/ .el-icon, /deep/ .el-icon svg {
  width: 1.8rem;
  height: 1.8rem;
}

.dialog-content {
  padding: 0 80px;


  .item-text {
    font-size: 18px;
    line-height: 30px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .item-value {
    width: 560px;
    height: 60px;
    margin-bottom: 23px;
    background: #F3F5F9;
    border-radius: 11.9302px;
  }


  /deep/ .el-input__wrapper {
    &.item-area {
      height: auto;
    }

    width: 560px;
    height: 60px;
    border: none;
    box-shadow: none;
    background: #F3F5F9;
    border-radius: 11.9302px;
    padding-left: 20px;
    font-size: 18px;
    line-height: 26px;
    font-weight: 600;
    color: #000000;
  }

  .contact-us-btn {
    width: 216px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #000000;
    border-radius: 50px;
    color: #75CDFF;
    margin: 78px auto 40px;
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
  }

}

/deep/ .el-dialog {
  border-radius: 40px;
}

/deep/ .el-dialog__headerbtn {
  top: 24px;
  right: 24px;
}

/deep/ .el-dialog__header {
  display: none;
}


/deep/ .el-textarea__inner {
  box-shadow: none;
  background: #F3F5F9;
  border-radius: 11.9302px;
  resize: none;
  font-size: 18px;
  font-weight: 600;
  line-height: 25px;
}

.about-container {
  width: 720px;
  height: 541px;
  background: #FFFFFF;
  border-radius: 40px;
  overflow: hidden;

  img {
    width: 720px;
    height: 277px;
    margin-bottom: 60px;
  }


}

.close-btn {
  width: 42px !important;
  height: 42px !important;
  border-radius: 50%;
  position: absolute;
  top: 24px;
  right: 24px;
}
</style>