import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/common.css' // global style

import ElementPlus from 'element-plus'
import 'element-plus/theme-chalk/index.css'


createApp(App).use(router).use(ElementPlus).mount('#app')
